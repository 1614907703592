import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaChevronDown, FaChevronUp } from "react-icons/fa";
import Modal from "react-modal";

// Appliquer les styles du modal
Modal.setAppElement('#root');

export default function Home(props) {
  const [motorsList, setMotorsList] = useState([]);
  const [filteredMotorsList, setFilteredMotorsList] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [filterClient, setFilterClient] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [clients, setClients] = useState([]);
  const [locations, setLocations] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [motorToDelete, setMotorToDelete] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null); 
  const navigate = useNavigate();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const openModal = (motor) => {
    setMotorToDelete(motor);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setMotorToDelete(null);
  };

  const confirmDelete = () => {
    setMotorsList(motorsList.filter(motor => motor.id !== motorToDelete.id));
    closeModal();
  };

  useEffect(() => {
    const fakeData = [
      {
        id: 1,
        name: "Compteur A",
        dateOfService: moment().subtract(1, "year").format("YYYY-MM-DD"),
        totalConsumption: 1000,
        alertCount: 2,
        clientName: "Client A",
        location: "Localisation A"
      },
      {
        id: 2,
        name: "Compteur B",
        dateOfService: moment().subtract(2, "years").format("YYYY-MM-DD"),
        totalConsumption: 1500,
        alertCount: 1,
        clientName: "Client B",
        location: "Localisation B"
      },
      {
        id: 3,
        name: "Compteur C",
        dateOfService: moment().subtract(6, "months").format("YYYY-MM-DD"),
        totalConsumption: 800,
        alertCount: 0,
        clientName: "Client C",
        location: "Localisation C"
      },
      {
        id: 4,
        name: "Compteur D",
        dateOfService: moment().subtract(3, "months").format("YYYY-MM-DD"),
        totalConsumption: 500,
        alertCount: 3,
        clientName: "Client D",
        location: "Localisation D"
      },
      {
        id: 5,
        name: "Compteur E",
        dateOfService: moment().subtract(5, "years").format("YYYY-MM-DD"),
        totalConsumption: 2000,
        alertCount: 0,
        clientName: "Client E",
        location: "Localisation E"
      },
      {
        id: 6,
        name: "Compteur F",
        dateOfService: moment().subtract(4, "years").format("YYYY-MM-DD"),
        totalConsumption: 1200,
        alertCount: 1,
        clientName: "Client F",
        location: "Localisation F"
      },
      {
        id: 7,
        name: "Compteur G",
        dateOfService: moment().subtract(2, "months").format("YYYY-MM-DD"),
        totalConsumption: 900,
        alertCount: 2,
        clientName: "Client G",
        location: "Localisation G"
      },
      {
        id: 8,
        name: "Compteur H",
        dateOfService: moment().subtract(7, "months").format("YYYY-MM-DD"),
        totalConsumption: 1100,
        alertCount: 0,
        clientName: "Client H",
        location: "Localisation H"
      }
    ];
    setMotorsList(fakeData);

    // Extraire les clients et localisations uniques
    const uniqueClients = ["Tous les clients", ...new Set(fakeData.map(motor => motor.clientName))];
    const uniqueLocations = ["Toutes les localisations", ...new Set(fakeData.map(motor => motor.location))];

    setClients(uniqueClients);
    setLocations(uniqueLocations);
  }, []);

  useEffect(() => {
    let filteredList = motorsList;

    if (searchName) {
      filteredList = filteredList.filter((motor) =>
        motor.name.toLowerCase().includes(searchName.toLowerCase())
      );
    }

    if (filterClient && filterClient !== "Tous les clients") {
      filteredList = filteredList.filter((motor) =>
        motor.clientName.toLowerCase().includes(filterClient.toLowerCase())
      );
    }

    if (filterLocation && filterLocation !== "Toutes les localisations") {
      filteredList = filteredList.filter((motor) =>
        motor.location.toLowerCase().includes(filterLocation.toLowerCase())
      );
    }

    setFilteredMotorsList(filteredList);
  }, [searchName, filterClient, filterLocation, motorsList]);

  const navigateToDetails = (motor) => {
    navigate('/details', { state: { motor } });
  };

  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full justify-center">
        <div className="p-4 h-fit flex flex-col items-center">
          <h1 className="text-bleuSTB mb-4 font-bold text-3xl xl:text-5xl 3xl:text-6xl">
            Liste des compteurs
          </h1>
          <div className="flex flex-col lg:flex-row justify-between items-center mb-4">
            <div className="flex-1 lg:mr-4">
              <input
                type="text"
                placeholder="Recherche par nom du compteur"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                className="mt-4 mb-4 shadow-md w-70 sm:w-96 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base sm:text-lg xl:text-xl border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6"
              />
            </div>
            <div className="lg:ml-4 shadow-md w-52 sm:w-72 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6 flex items-center">
              <select
                value={filterClient}
                onChange={(e) => setFilterClient(e.target.value)}
                className="outline-none border-none text-gray-700 bg-white w-full text-base sm:text-lg xl:text-xl 3xl:text-2xl"
              >
                {clients.map(client => (
                  <option key={client} value={client}>
                    {client}
                  </option>
                ))}
              </select>
            </div>
            <div className="lg:ml-4 mt-4 shadow-md w-52 sm:w-72 lg:w-80 xl:w-96 h-5 bg-white text-gray-700 outline-none text-base border-4 border-orangeButton hover:border-bleuSTB rounded-3xl p-6 flex items-center">
              <select
                value={filterLocation}
                onChange={(e) => setFilterLocation(e.target.value)}
                className="outline-none border-none text-gray-700 bg-white w-full text-base sm:text-lg xl:text-xl 3xl:text-2xl"
              >
                {locations.map(location => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="hidden lg:block w-full overflow-x-auto">
            <table className="cursor-pointer text-xs md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl">
              <thead className="hidden lg:contents">
                <tr>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Nom du compteur
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Date de mise en service
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Consommation totale d'eau
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Nom du client
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Localisation
                  </th>
                  <th scope="col" className="bg-bleuCielSTB text-white p-2 text-center border-2">
                    Alertes
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredMotorsList.map((motor, index) => (
                  <tr
                    className={`flex flex-col mb-10 ${index === hoveredRowIndex ? "bg-hoverTable" : ""} lg:table-row`}
                    key={motor.id}
                    onClick={() => {
                      navigateToDetails(motor);
                    }}
                    onMouseEnter={() => handleRowHover(index)}
                    onMouseLeave={() => handleRowHover(-1)}
                  >
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center text-marronSTB font-bold">
                      <span
                        className="lg:hidden"
                        style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                      ></span>
                      {motor.name}
                    </td>
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                      <span
                        className="lg:hidden"
                        style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                      >
                        Date de mise en service
                      </span>
                      {moment(motor.dateOfService).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                      <span
                        className="lg:hidden"
                        style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                      ></span>
                      {motor.totalConsumption} m³
                    </td>
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                      <span
                        className="lg:hidden"
                        style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                      ></span>
                      {motor.clientName}
                    </td>
                    <td className="text-end border-2 border-bleuCielSTB p-4 lg:text-center">
                      <span
                        className="lg:hidden"
                        style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                      ></span>
                      {motor.location}
                    </td>
                    <td
                      className={`text-end border-2 border-bleuCielSTB p-4 lg:text-center ${
                        motor.alertCount === 0 ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-700'
                      }`}
                    >
                      <span
                        className="lg:hidden"
                        style={{ float: "left", fontWeight: 500, color: "#f48507", marginRight: "5em" }}
                      ></span>
                      <span>
                        {motor.alertCount === 0
                          ? "Fonctionnement normal"
                          : `${motor.alertCount} ${motor.alertCount === 1 ? "alerte" : "alertes"}`
                        }
                      </span>
                    </td>
                    <td className="text-center border-2 border-bleuCielSTB p-4 lg:text-center">
                      <FaTrash
                        className="text-bleuSTB hover:text-marronSTB text-3xl cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          openModal(motor);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Confirmation de suppression"
              className="fixed inset-0 flex items-center justify-center p-4"
              overlayClassName="fixed inset-0 bg-black bg-opacity-60"
            >
              <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md mx-auto transform transition-transform duration-300 scale-100 hover:scale-105">
                <h2 className="text-2xl font-semibold mb-5 text-bleuSTB">Confirmation</h2>
                <p className="text-gray-700 mb-6">Êtes-vous sûr de vouloir supprimer ce compteur ?</p>
                <div className="flex gap-4 justify-end">
                  <button
                    onClick={confirmDelete}
                    className="bg-orangeALF text-white px-5 py-3 rounded-lg hover:bg-orange-500 transition-colors duration-300"
                  >
                    Oui
                  </button>
                  <button
                    onClick={closeModal}
                    className="bg-gray-200 text-gray-800 px-5 py-3 rounded-lg hover:bg-gray-300 transition-colors duration-300"
                  >
                    Non
                  </button>
                </div>
              </div>
              
            </Modal>
          </div>
          <div className="block lg:hidden w-full">
            {filteredMotorsList.map((motor, index) => (
              <div key={motor.id} className="mb-4 w-full border border-bleuSTB shadow-md rounded-lg">
                <div className="p-4 flex justify-between items-center cursor-pointer">
                  <div
                    className="flex items-center space-x-4"
                    onClick={() => navigateToDetails(motor)}
                  >
                    <span className="text-sm md:text-2xl text-bleuSTB font-bold mr-16">{motor.name}</span>
                    <span className="text-xs md:text-base text-end">Client: {motor.clientName}</span>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(index);
                    }}
                  >
                    {expandedIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
                {expandedIndex === index && (
                  <div className="p-4 border-t border-gray-200">
                    <div className="flex flex-col space-y-4">
                      <div>
                        <span className="font-bold text-base md:text-xl text-bleuSTB">Date de mise en service:</span> <span className="text-base ml-1">{moment(motor.dateOfService).format("DD/MM/YYYY")}</span>
                      </div>
                      <div>
                      <span className="font-bold text-base md:text-xl text-bleuSTB">Consommation totale d'eau:</span> <span className="text-base ml-1">{motor.totalConsumption}m³</span>
                      </div>
                      <div>
                      <span className="font-bold text-base md:text-xl text-bleuSTB">Localisation:</span> <span className="text-sm md:text-base ml-1">{motor.location}</span>
                      </div>
                      <div className={`p-2 rounded ${motor.alertCount > 0 ? "bg-red-300" : "bg-green-300"}`}>
                      <span className="font-semibold text-base md:text-xl">Alertes:</span> <span className="text-sm md:text-base ml-1">{motor.alertCount === 0
                          ? "Fonctionnement normal"
                          : `${motor.alertCount} ${motor.alertCount === 1 ? "alerte" : "alertes"}`}</span>
                      </div>
                      <div className="flex justify-end mt-4">
                          <button
                            className="bg-red-600 hover:bg-red-700 text-white text-base hover:text-white px-4 py-2 rounded-md"
                            onClick={(e) => {
                              e.stopPropagation();
                              openModal(motor);
                            }}
                          >
                            Supprimer
                          </button>
                        </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
