import React, { useState, useEffect } from "react";
import axios from "axios"; 
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";

export default function EditUserForm({ id, rafraichir, token, admin }) {
  const [newUser, setNewUser] = useState({ mail: "" });
  const [messageMailInvalide, setMessageMailInvalide] = useState("");
  const [messageUpdateMail, setMessageUpdateMail] = useState("");
  const navigate = useNavigate();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessageMailInvalide("");
      setMessageUpdateMail("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messageMailInvalide, messageUpdateMail]);

  const handleChange = (event) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(newUser.mail)) {
      setMessageMailInvalide("Adresse e-mail invalide");
      return;
    }

    const userId = id;
    const userEmail = newUser.mail;

    axios.patch(`${API_URL}/user/update`, {
      userId: userId,
      email: userEmail,
      admin: admin,
    }, {
      headers: {
        "accept": "*/*",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 401) {
          showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
          localStorage.clear();
          navigate.push("/login");
        } else {
          return res.data;
        }
      })
      .then(
        (result) => {
          setMessageUpdateMail(result.message);
          rafraichir();
        },
        (error) => {
          console.log(error.message);
        }
      );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center text-center">
      <label className="flex flex-col">
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-bleuCielSTB hover:border-bleuSTB rounded-3xl p-4 text-center mt-4 mb-4"
          onChange={handleChange}
          value={newUser.mail}
          placeholder="Nouvelle adresse mail"
          name="mail"
          type="email"
        />
      </label>
      <input
        type="submit"
        value="Changer l'adresse mail"
        className="bg-bleuSTB hover:bg-marronSTB text-white text-sm 3xl:text-xl border-none rounded-full p-2 w-11/12 lg:w-60 mr-auto ml-auto cursor-pointer"
      />
      {messageMailInvalide && (
        <p className="bg-error rounded-lg text-white text-xl mx-auto p-2 text-center mt-8 flex items-center justify-center">
          {messageMailInvalide}
        </p>
      )}
      {messageUpdateMail && (
        <p className="bg-green-500 rounded-lg text-white mx-auto p-2 text-center my-4 text-xl flex items-center justify-center">
          {messageUpdateMail}
        </p>
      )}
    </form>
  );
}
