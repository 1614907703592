import React, { useState, useEffect } from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import UserTable from "./UserTable";
import AddUserForm from "./AddUserForm";
import EditUserForm from "./EditUserForm";
import SetDefaultEmailFrequency from './SetDefaultEmailFrequency';
import ChangePasswordForm from './ChangePasswordForm';
import CapteurList from './CapteurList';
import SearchCapteur from './SearchCapteur';

export default function Admin(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;

  // Gestion des utilisateurs en local
  const [users, setUsers] = useState([
    { id: 1, email: "user1@example.com", isAdmin: false },
    { id: 2, email: "admin@example.com", isAdmin: true },
    { id: 3, email: "user2@example.com", isAdmin: false },
    { id: 4, email: "user3@example.com", isAdmin: false },
    { id: 5, email: "user4@example.com", isAdmin: true },
    { id: 6, email: "user5@example.com", isAdmin: false }
  ]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCapteur, setSelectedCapteur] = useState(null);
  const [showMoteurList, setShowMoteurList] = useState(true);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSelectedCapteur(null);
    setShowMoteurList(true);
  };

  const handleAjouterCapteur = () => {
    window.location.reload();
  };

  // Simuler une liste de capteurs
  const fakeCapteurs = [
    { _id: 1, nom_capteur: "Capteur - 1" },
    { _id: 2, nom_capteur: "Capteur - 2" },
    { _id: 3, nom_capteur: "Capteur - 3" },
    { _id: 4, nom_capteur: "Capteur - 4" },
    { _id: 5, nom_capteur: "Capteur - 5" },
  ];

  const filteredCapteurs = fakeCapteurs.filter((capteur) => {
    return (
      capteur.nom_capteur.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleCapteurClick = (capteur) => {
    setSelectedCapteur(capteur.nom_capteur);
    setSearchTerm(capteur.nom_capteur);
    setShowMoteurList(false);
  };

  const deleteUser = (userId) => {
    setUsers(users.filter(user => user.id !== userId));
  };

  const updateUser = (updatedUser) => {
    setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user));
  };

  const toggleAdmin = (userId) => {
    setUsers(users.map(user => 
      user.id === userId ? { ...user, isAdmin: !user.isAdmin } : user
    ));
  };

  useEffect(() => {
    if (selectedUserId) {
      const user = users.find(user => user.id === selectedUserId);
      setSelectedUserEmail(user.email);
    }
  }, [selectedUserId, users]);

  const handleEditClick = (userId, userEmail) => {
    setSelectedUserId(userId);
    setSelectedUserEmail(userEmail);
  };

  const handleCloseEditForm = () => {
    setIsEditing(false);
    setSelectedUserId(null);
    setSelectedUserEmail("");
  };

  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full w-full items-center justify-center p-4">
        <div className="border-4 border-bleuSTB rounded-2xl w-full text-xs sm:text-base sm:w-auto flex flex-col-reverse  lg:flex-row p-6">
          <div id="flex flex-col items-center">
            <h2 className="text-center text-bleuSTB mb-6 text-lg sm:text-xl xl:text-5xl font-bold">Gestion Utilisateurs</h2>
            {isEditing ? (
              <EditUserForm
                userId={selectedUserId}
                userEmail={selectedUserEmail}
                onClose={handleCloseEditForm}
                onUpdateUser={updateUser}
              />
            ) : (
              <>
                <UserTable
                  users={users}
                  deleteUser={deleteUser}
                  setSelectedUserId={setSelectedUserId}
                  setSelectedUserEmail={setSelectedUserEmail}
                  setAdminRole={toggleAdmin}
                  onEditUser={handleEditClick}
                />
              </>
            )}
          </div>
          <div className="flex flex-col p-8">
            <div className="flex flex-col border-4 border-bleuSTB rounded-2xl p-6 mb-4 ">
              <AddUserForm />
            </div>
            {selectedUserId ? (
              <div className="flex flex-col border-4 border-bleuSTB rounded-2xl p-4 text-base lg:text-2xl">
                <span className="text-center text-bleuSTB 3xl:text-3xl">{selectedUserEmail}</span>
                <EditUserForm />
                <ChangePasswordForm />
                <>
                <SearchCapteur
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                    selectedCapteur={selectedCapteur}
                    handleAjouterCapteur={handleAjouterCapteur}
                  />
                  {showMoteurList && searchTerm !== "" && filteredCapteurs.length > 0 && (
                    <CapteurList
                    filteredCapteurs={filteredCapteurs}
                    handleCapteurClick={handleCapteurClick}
                    />
                  )}
                </>
              </div>
            ) : (
              <></>
            )}
            <div className="flex flex-col border-4 border-bleuSTB rounded-2xl mt-4 p-4 text-base lg:text-2xl">
              <SetDefaultEmailFrequency />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
