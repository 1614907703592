import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";

export default function AddUserForm({ rafraichir, token }) {
  const [inscriptionUser, setInscriptionUser] = useState({ mail: "" });
  const [messageMailUser, setMessageMailUser] = useState("");
  const [messageMailInvalide, setMessageMailInvalide] = useState("");
  const [isAdmin, setIsAdmin] = useState(false); 
  const navigate = useNavigate();

  const clearMessages = () => {
    setTimeout(() => {
      setMessageMailUser("");
      setMessageMailInvalide("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messageMailUser, messageMailInvalide]);

  const handleChange = (event) => {
    if (event.target.name === "isAdmin") {
      setIsAdmin(event.target.checked);
    } else {
      setInscriptionUser({
        ...inscriptionUser,
        [event.target.name]: event.target.value,
      });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(inscriptionUser.mail)) {
      setMessageMailInvalide("Adresse e-mail invalide");
      return;
    }

    axios.post(`${API_URL}/user/signupadmin`, {
      email: inscriptionUser.mail,
      isAdmin: isAdmin,
    }, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      if (res.status === 401) {
        showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
        localStorage.clear();
        navigate.push("/login");
      } else if (res.status === 409) {
        return res.data.error.then((errorData) => {
          setMessageMailUser("");
          setMessageMailInvalide(errorData.error);
        });
      } else {
        return res.data;
      }
    })
    .then(
      (result) => {
        if (result.message) {
          setMessageMailUser(result.message);
        }
        rafraichir();
        setInscriptionUser({ ...inscriptionUser, mail: "" });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center text-center">
      <label className="flex flex-col text-base lg:text-2xl text-center text-bleuSTB 3xl:text-3xl">
        Ajouter un Utilisateur
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-bleuCielSTB hover:border-bleuSTB rounded-2xl p-4 text-center mt-4 mb-4"
          type="mail"
          name="mail"
          placeholder="Adresse mail"
          onChange={handleChange}
          value={inscriptionUser.mail}
        />
      </label>
      <label className="block text-center text-base 3xl:text-xl  lg:text-left mb-4">
        <span className="titreCheckbox">Admin</span>
        <input
          type="checkbox"
          name="isAdmin"
          checked={isAdmin}
          onChange={handleChange}
          className="ml-4"
        />
      </label>
      <input type="submit" value="Ajouter" className="bg-bleuSTB hover:bg-marronSTB text-white lg:text-sm 3xl:text-xl border-none rounded-full p-2 w-11/12 mt-5 lg:mt-0 lg:w-60 mr-auto lg:ml-8 cursor-pointer"/>
      {messageMailUser && <p className="bg-green-500 rounded-lg text-white mx-auto p-2 text-center my-4 text-xl flex items-center justify-center">{messageMailUser}</p>}
      {messageMailInvalide && <p className="bg-error rounded-lg text-white text-xl mx-auto p-2 text-center mt-8 flex items-center justify-center">{messageMailInvalide}</p>}
    </form>
  );
}
